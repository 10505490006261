import clsx from 'clsx';
import { SeparatorProps, Separator } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

function _Separator({ orientation, className, ...props }: SeparatorProps) {
  return (
    <Separator
      className={twMerge(
        clsx(
          'text-grey-300 bg-grey-300 inline-flex',
          orientation === 'vertical' ? 'mx-4 min-h-0 w-px' : 'my-6 h-px w-full min-w-0',
        ),
        className,
      )}
      orientation={orientation}
      {...props}
    />
  );
}

export { _Separator as Separator };
